import $ from "jquery";
import Compressor from 'compressorjs';
// export const ajaxUrl = "http://jszccrm2.jctmj.cn/index.php";
export const ajaxUrl = "http://scrm.3dian.store/index.php";
// export const ajaxUrl = "http://sdcrmys.jctmj.cn/index.php";
// export const ajaxUrl = "http://scrmapp.jctmj.cn/index.php";
// export const ajaxUrl = "http://jb2.scrm.cn/index.php";
// const ajaxUrl = "http://scrms.net/index.php";
// const ajaxUrl = "http://local.scrm.net/index.php";
import {
    message
} from 'ant-design-vue';
// import Antd from 'ant-design-vue';
export function requestXml(url, xmlType, callback, xmlData) {
    xmlData = xmlData || {};
    $.ajax(ajaxUrl + url, {
        data: xmlData,
        type: xmlType, // HTTP请求类型
        success: (res) => {
            if (res.code == 1) {
                var data = res.data || res.apply;
                if (data) {
                    callback(data);
                } else {
                    message.info(res.msg)
                    callback("success");
                }
            } else if (res.code == -1) {
                window.location.href = ajaxUrl + "/scrm/login/login?url=" + location.href
            } else if (res.code == -2) {
                callback("no")
            } else {
                message.info(res.msg)
                callback("error");
            }
        },
        error: function (error) {
            //异常处理；
            message.error(error)
        }
    });
}

export function getDateTime(time, type = 1, flag = 1) {
    if (time == null) {
        return;
    }
    var date;
    if (flag == 0) {
        date = new Date(time);
    } else {
        date = new Date(parseInt(time) * 1000);
    }
    var year = date.getFullYear();
    var mon = formatNumber(date.getMonth() + 1);
    var day = formatNumber(date.getDate());
    var hours = formatNumber(date.getHours());
    var minu = formatNumber(date.getMinutes());
    var sec = formatNumber(date.getSeconds());
    if (type == 1) {
        return year + '-' + mon + '-' + day;
    } else if (type == 2) {
        return hours + ':' + minu;
    } else if (type == 3) {
        return year + '-' + mon + '-' + day + '  ' + hours + ':' + minu;
    } else if (type == 4) {
        return hours + ':' + minu + ':' + sec;
    } else if (type == 5) {
        return year + '-' + mon;
    } else {
        return year + '-' + mon + '-' + day + '  ' + hours + ':' + minu + ':' + sec;
    }
}

function formatNumber(n) {
    if (parseInt(n) < 10) {
        return "0" + parseInt(n);
    } else {
        return n;
    }
}
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

//压缩上传
export function fileUpload(e, callback) {

    let files = e.target.files[0];
    // let formData = new FormData(); //构造一个
    // formData.append('file', files);
    if (files.name.split('.')[files.name.split('.').length - 1] != 'jpeg' || files.name.split('.')[files.name.split('.').length - 1] != 'jpg' || files.name.split('.')[files.name.split('.').length - 1] != 'png') {
        let files = e.target.files[0];
        let formData = new FormData(); //构造一个
        formData.append('file', files);

        $.ajax({
            url: ajaxUrl + "/scrm/index/UpFiles",
            dataType: 'json',
            type: 'POST',
            data: formData,
            processData: false, // 使数据不做处理
            contentType: false, // 不要设置Content-Type请求头
            success: (res) => {

                if (res.code == 1) {
                    var data = res.data || res.apply;
                    if (data) {
                        callback(data);
                    } else {
                        // alert(res.msg);		
                        callback("success");
                    }
                }
            },
            error: () => {

            }
        })
        return
    }
    try {
        const options = {
            quality: 0.2, // 设置压缩质量，范围0-1  
            convertSize: 1024 * 1024, // 转换后的图片大小，单位字节  
            mimeType: 'image/jpeg', // 转换后的图片类型  
            success(result) {
                // 压缩成功后的回调，result为压缩后的blob对象  
                // 这里可以处理压缩后的图片，比如预览或上传  
                const formData = new FormData();
                formData.append('file', result, files.name);
                // 假设你有一个上传图片的方法uploadImage  
                // this.uploadImage(formData);  
                $.ajax({
                    url: ajaxUrl + "/scrm/index/UpFiles",
                    dataType: 'json',
                    type: 'POST',
                    data: formData,
                    processData: false, // 使数据不做处理
                    contentType: false, // 不要设置Content-Type请求头
                    success: (res) => {

                        if (res.code == 1) {
                            var data = res.data || res.apply;
                            if (data) {
                                callback(data);
                            } else {
                                // alert(res.msg);		
                                callback("success");
                            }
                        }
                    },
                    error: () => {

                    }
                })
            },
            error(err) {
                console.log(err.message);
            },
        };

        new Compressor(files, options);
    } catch (error) {
        console.error('压缩图片时发生错误:', error);
    }
}

// export function fileUpload(e, callback) {

//     let files = e.target.files[0];
//     let formData = new FormData(); //构造一个
//     formData.append('file', files);

//     $.ajax({
//         url: ajaxUrl + "/scrm/index/UpFiles",
//         dataType: 'json',
//         type: 'POST',
//         data: formData,
//         processData: false, // 使数据不做处理
//         contentType: false, // 不要设置Content-Type请求头
//         success: (res) => {

//             if (res.code == 1) {
//                 var data = res.data || res.apply;
//                 if (data) {
//                     callback(data);
//                 } else {
//                     // alert(res.msg);
//                     callback("success");
//                 }
//             }
//         },
//         error: () => {

//         }
//     })
// }
// export default requestXml
// module.exports = {
//     requestXml: requestXml,
//     getDateTime: getDateTime,
// }